import {IAppliedCoupon} from '../../types/app.types';
import {Coupon} from '@wix/ecom_current-cart';

export class AppliedCouponModel {
  public id: string;
  public code: string;
  /**
   * @deprecated
   */
  public name?: string;

  constructor(params: AppliedCouponModel) {
    this.id = params.id;
    this.code = params.code;
    this.name = params.name;
  }

  public static fromGQL(appliedCoupon: IAppliedCoupon) {
    return new AppliedCouponModel({
      id: appliedCoupon?.couponId,
      code: appliedCoupon?.code,
      name: appliedCoupon?.name,
    });
  }

  public static fromSDK(appliedCoupon: Coupon) {
    return new AppliedCouponModel({
      id: appliedCoupon?._id,
      code: appliedCoupon?.code,
    });
  }
}
